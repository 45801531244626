<template>
  <div>
    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="agent_bill_id"
    >
      <div slot="agents_product_name" slot-scope="agents_product_name, record">
        <router-link
          :to="{ name: 'monthly_bill_stop_info',
                 params: { stop_id: record.product_stop_order_id },
                 query: { bill_id: billId }}"
        >
          {{ agents_product_name }}
        </router-link>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findStopBills } from '@/api/agent_monthly_bill'
import { formatBigNumber, formatCurrency } from '@/utils/filter'

export default {
  name: 'AgentStopBillList',
  components: {
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  props: {
    billId: {
      type: Number,
      required: true
    }
  },
  computed: {
    columns() {
      return [
        { title: '套餐名称', dataIndex: 'agents_product_name', scopedSlots: { customRender: 'agents_product_name' }},
        { title: '卡号总数', dataIndex: 'cards_count', customRender: formatBigNumber },
        { title: '停机保号资费(元)', dataIndex: 'stop_price', customRender: formatCurrency },
        { title: '总费用(元)', dataIndex: 'total_fee', customRender: formatCurrency },
        { title: '备注', dataIndex: 'remark', ellipsis: true }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findStopBills(this.billId, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>

